import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  screen,
  innerWidth,
  mainWhite,
  transHover,
  mainBlack,
  highlight,
} from "../components/common/variables";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 80px;
  @media ${screen.small} {
    padding-top: 135px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 90px;
  }
  @media ${screen.medium} {
    padding-top: 270px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 120px;
  }
  @media ${screen.xlarge} {
    padding-top: 328px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 180px;
  }

  .contact {
    max-width: ${innerWidth};
    margin: 0 auto;
    @media ${screen.small} {
      margin: 0 auto;
    }
    @media ${screen.medium} {
      margin: 0 auto;
    }
    @media ${screen.large} {
      margin: 0 auto;
    }

    &__heading {
      font-size: 1.5rem;
      font-weight: 600;
      text-align: center;
      margin: 0 0 15px 0;
      @media ${screen.small} {
        font-size: 2.5rem;
      }
      @media ${screen.xlarge} {
        font-size: 2.7rem;
      }
    }

    &__description {
      text-align: center;
      p {
        margin: 0 0 10px 0;
        font-size: 0.9rem;
        @media ${screen.small} {
          font-size: 1rem;
        }
      }

      a {
        transition: ${transHover};
        @media ${screen.withCursor} {
          &:hover {
            color: ${highlight};
          }
        }
      }
    }

    form {
      max-width: 800px;
      margin: 40px auto 0 auto;
      @media ${screen.small} {
        margin: 45px auto 0 auto;
      }
      p {
        margin: 0 0 11px 0;
        display: flex;
        justify-content: space-between;
        @media ${screen.small} {
          margin: 0 0 20px 0;
        }
        input {
          width: 100%;
          padding: 11px 5px;
          font-size: 1.05rem;
          font-weight: 300;
        }
        textarea {
          width: 100%;
          font-size: 1.05rem;
          font-weight: 300;
          padding: 11px 5px;
          height: 300px;
        }
      }
      .two-col {
        input {
          width: 49%;
        }
      }
      button {
        color: ${mainWhite};
        cursor: pointer;
        width: 100%;
        display: block;
        background: ${mainBlack};
        border: 0;
        border-radius: 2px;
        padding: 11px 5px;
        font-size: 1.05rem;
        transition: ${transHover};
        @media ${screen.withCursor} {
          &:hover {
            background: ${highlight};
          }
        }
      }
    }
  }
`;

const ContactPage = ({ data }) => {
  const { title_tag, meta_description, contact_details } = data.content.data;

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="contact">
          <h1 className="contact__heading">WANT TO SAY HI?</h1>
          <div
            className="contact__description"
            dangerouslySetInnerHTML={{ __html: contact_details.html }}
          />
          <form
            name="Nick Bolton Website"
            method="POST"
            data-netlify="true"
            action="/contact/success"
            data-netlify-honeypot="bot-field"
          >
            <p className="two-col">
              <input
                type="hidden"
                name="form-name"
                value="Nick Bolton Website"
              />

              <input type="text" name="name" placeholder="Name*" required />
              <input type="email" name="email" placeholder="Email*" required />
            </p>
            <p>
              <input type="text" name="company" placeholder="Company" />
            </p>
            <p>
              <textarea
                name="message"
                placeholder="What's on your mind?"
              ></textarea>
            </p>
            <button type="submit">Send</button>
          </form>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default ContactPage;

export const dataQuery = graphql`
  {
    content: prismicContactPage {
      data {
        title_tag
        contact_details {
          html
        }
        meta_description
      }
    }
  }
`;
